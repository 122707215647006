// ----------------------------------------------------------------------

// SI CETTE TRADUCTION EST INCORRECTE, VEUILLEZ L'IGNORER CAR CETTE TRADUCTION EST UNIQUEMENT À DES FINS DE DÉMO
// Nous sommes heureux si vous pouvez aider à améliorer la traduction en envoyant un e-mail à support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  demo: {
    title: 'Français',
    introduction:
      "Lorem Ipsum est simplement un faux texte de l'industrie de l'impression et de la composition. Le Lorem Ipsum est le texte factice standard de l'industrie depuis les années 1500, lorsqu'un imprimeur inconnu a pris une galère de caractères et l'a brouillé pour en faire un livre de spécimens de caractères. Il a survécu non seulement à cinq siècles, mais aussi au saut dans la composition électronique, restant essentiellement inchangé. Il a été popularisé dans les années 1960 avec la sortie de feuilles Letraset contenant des passages de Lorem Ipsum, et plus récemment avec des logiciels de publication assistée par ordinateur comme Aldus PageMaker comprenant des versions de Lorem Ipsum.",
  },
  docs: {
    hi: 'Salut',
    description: "Besoin d'aide? \n Veuillez consulter nos documents.",
    documentation: 'documentation',
  },
  dont_have_an_account: "Vous n'avez pas de compte ?",
  hi_welcome_back: 'Salut, bienvenue à nouveau',
  sign_in_to: 'Connectez-vous à',
  enter_your_details_below: 'Entrez vos coordonnées ci-dessous.',
  get_started: 'Commencer',
  email_address: 'Adresse e-mail',
  address: 'Adresse',
  city: 'Ville',
  state: 'État',
  password: 'le mot de passe',
  quick_links: 'Liens rapides',
  zip: 'code postal',
  forgot_password: 'mot de passe oublié?',
  country: 'Pays',
  select_country: 'Choisissez le pays',
  login: 'Connexion',
  email_must_be_a_valid_email_address: 'Email invalide',
  email_is_required: "L'e-mail est requis",
  city_is_required: 'La ville est obligatoire',
  state_is_required: "L'état est obligatoire",
  zip_is_required: 'Le code postal est requis',
  password_is_required: 'Mot de passe requis',
  already_have_an_account: 'Vous avez déjà un compte?',
  register_with_mwbapps_heading:
    'Inscrivez-vous à MWBApps pour obtenir des paiements en douceur de votre client.',
  get_started_absolutely_free: 'Commencez tout à fait gratuitement.',
  seven_days_trail_no_credit_card:
    "7 jours d'essai gratuit. Pas de carte de crédit nécessaire,",
  by_registering_i_agree_to_mwbapps: "En m'inscrivant, j'accepte MWBApps",
  terms_of_service: "Conditions d'utilisation",
  and: 'et',
  privacy_policy: 'Politique de confidentialité',
  passwords_must_match: 'Les mots de passe doivent correspondre',
  resend_verification: 'Renvoyer vérification',
  login_here: 'Connectez-vous ici',
  new_password: 'nouveau mot de passe',
  confirm_password: 'Confirmez le mot de passe',
  save_password: 'Sauvegarder le mot de passe',
  invalid_token_verification: 'Vérification de jeton invalide',
  confirm_new_password: 'Confirmer passe',
  forgot_your_password: 'Mot de passe oublié?',
  update_form: 'Formulaire de mise à jour',
  publish_form: 'Publier le formulaire',
  please_enter_email_to_get_link:
    "Veuillez saisir l'adresse e-mail associée à votre compte. Nous vous enverrons un lien par e-mail pour réinitialiser votre mot de passe.",
  back_to_login: 'Retour connexion',
  password_change_successfully: 'Le mot de passe a été changé avec succès',
  back_to_register: "Retour à l'inscription",
  request_sent_sucessfully: 'Demande envoyée avec succès',
  sent_confirmation_to: 'Nous avons envoyé un e-mail de confirmation à',
  please_check_your_email: 'Merci de consulter vos emails.',
  back: 'Arrière',
  email_verified_successfully: 'Courriel vérifié avec succès',
  go_to_login: 'Aller à Connexion',
  emailed_a_link_to_verify:
    'Nous avons envoyé un lien pour vérifier votre adresse e-mail',
  dont_have_email: "Vous n'avez pas de mail ?",
  resend_email: "Ré-envoyer l'email",
  email_not_verified_try_again: 'E-mail non vérifié Réessayez',
  recheck_your_email_or_register_again:
    'Il y a quelque chose qui ne va pas, veuillez re-cliquer sur votre lien e-mail ou vous inscrire à nouveau à partir de la connexion.',
  code_is_required: 'Le code est requis',
  code: 'code',
  comments: 'commentaires',
  verify: 'Vérifier',
  verification_link_resent:
    'Lien de vérification renvoyé, veuillez vérifier la boîte aux lettres.',
  resend: 'Renvoyer',
  register: "S'inscrire",
  id: 'identifiant:',
  need_help: "Besoin d'aide",
  logout: 'Se déconnecter',
  connect_portals: 'Connecter les portails',
  choose_listed_portals:
    "Choisissez parmi l'un des portails HubSpot répertoriés. Si votre portail n'est pas répertorié, pour créer un nouveau portail, accédez à HubSpot",
  add_new_portal: 'Ajouter un nouveau portail',
  no_portal_found: 'Aucun portail trouvé',
  no_portal_added_yet_click_add_new_portal_btn:
    "Aucun portail n'a encore été ajouté. Vous pouvez ajouter de nouveaux portails en cliquant sur le bouton Ajouter un nouveau portail",
  portal_listing: 'Liste de portail',
  support: 'Soutien',
  hubspot_portals: 'Portails Hubspot',
  mailed_six_digit_code:
    'Nous avons envoyé un code de confirmation à 6 chiffres à acb@domain, veuillez saisir le code dans la case ci-dessous pour vérifier votre adresse e-mail.',
  resend_code: 'Renvoyer le code',
  active: 'Activé',
  inActive: 'Inactivé',
  dashboard: 'Tableau de bord',
  tutorial: 'Didacticiel',
  schedule_meeting:
    'Planifiez une réunion individuelle avec notre expert en implémentation pour vous aider à intégrer MWBApps',
  schedule_now: 'Programmez maintenant',
  active_gateways: 'Passerelles actives',
  welcome: 'Bienvenu',
  name: 'Nom',
  created: 'Créé à',
  type: 'Taper',
  select_brand: 'Sélectionner la marque',
  action: 'Action',
  form_listing: 'Liste des formulaires',
  forms: 'Formes',
  form: 'Formulaire',
  stripe_and_paypal_to_be_comming_soon:
    'Stripe en Paypal zijn de volgende gateways die binnenkort worden geïntegreerd',
  form_list: 'Liste des formulaires',
  create_new_form: 'Créer un nouveau formulaire',
  name_is_required: 'Le nom est requis',
  country_is_required: 'Le pays est requis',
  account_single: 'Compte',
  didnt_gateway: "Vous n'avez pas trouvé votre passerelle ?",
  single_document: 'Documentation',
  connect_with_us: "Encore besoin d'aide ? Connecte-toi avec nous",
  contact_now: 'Contacter maintenant',
  new_coming_soon: 'De nouvelles passerelles arrivent bientôt',
  amount_does_not_match_with_plan:
    'Le montant ne correspond pas au plan sélectionné.',
  phone_number_not_valid: 'Numéro de téléphone non valide',
  hubspot_form_is_required: 'Le formulaire HubSpot est requis',
  mode_is_required: 'Le mode est requis',
  fill_valid_amount: 'Remplissez le montant valide',
  select_currency: 'Sélectionnez la devise',
  payment_type: 'Type de paiement',
  payment_type_is_required: 'Le type de paiement est requis',
  select_atleast_one_gateway: 'Sélectionnez au moins 1 passerelle',
  edit: 'Modifier',
  form_settings: 'Paramètres du formulaire',
  payment_settings: 'Paramètres du formulaire',
  payment_method: 'Mode de paiement',
  delete_form: 'Supprimer le formulaire',
  publish_the_form_to_load_preview:
    "Veuillez publier le formulaire pour charger l'aperçu",
  please_fix_errors: 'Veuillez corriger les erreurs.',
  form_name: 'Nom de forme',
  select_hubspot_form: 'Sélectionnez le formulaire Hubspot',
  enter_amount: 'Entrer le montant',
  single: 'Seul',
  enable_test_mode: 'Activer le mode test',
  no_gateway_found: 'Aucune passerelle trouvée',
  currency: 'Monnaie',
  form_submission: 'Soumission du formulaire',
  form_submission_listing: 'Liste de soumission de formulaire',
  view_in_hubspot: 'Afficher dans HubSpot',
  status: 'Statut',
  date: 'Date',
  amount: 'montant',
  transaction: 'Transaction',
  transaction_listing: 'Liste des transactions',
  success: 'Succès',
  failed: 'Failed',
  payment_gateway: 'Passerelle de paiement',
  gateways: 'Passerelles',
  manage: 'Faire en sorte',
  setup: 'Installer',
  gatway_listing: 'Liste des passerelles',
  setup_instructions: 'Instructions de configuration',
  test_merchant_id_is_required: "L'identifiant du marchand de test est requis",
  test_store_id_is_required: "L'identifiant du magasin de test est requis",
  test_public_key_is_required: "L'ID de la clé publique de test est requis",
  test_private_key_file_is_required:
    'Le fichier de clé privée de test est requis',
  live_merchant_id_is_required:
    "L'identifiant du marchand en direct est requis",
  live_store_id_is_required: "L'identifiant du magasin en direct est requis",
  live_public_key_id_is_required:
    "L'identifiant de la clé publique en direct est requis",
  live_private_key_file_is_required:
    'Le fichier de clé privée en direct est requis',
  payment_region_is_required: 'La région de paiement est requise',
  payment_currency_is_required: 'La devise de paiement est requise',
  button_language_is_required: 'La langue du bouton est requise',
  button_color_is_required: 'La couleur du bouton est requise',
  product_type_is_required: 'Le type de produit est requis',
  test_account_details: 'Détails du compte test',
  merchant_id: 'Identifiant du marchand',
  store_id: 'Identifiant du magasin',
  public_key_id: 'Identifiant de clé publique',
  test_private_key_file: 'Tester le fichier de clé privée',
  live_account_details: 'Détails du compte en direct',
  live_private_key_file: 'Fichier de clé privée en direct',
  other_account_details: 'Autres détails du compte',
  payment_region: 'Région de paiement',
  payment_currency: 'Monnaie de paiement',
  button_language: 'Langue des boutons',
  button_color: 'Couleur du bouton',
  product_type: 'type de produit',
  activate_gateway: 'Activer la passerelle',
  test_api_key_is_required: 'La clé API de test est requise',
  test_merchant_account_is_required: 'Un compte marchand test est requis',
  test_client_key_is_required: 'La clé client de test est requise',
  live_api_key_is_required: 'La clé API en direct est requise',
  live_merchant_account_name_is_required:
    'Le nom du compte marchand en direct est requis',
  live_client_key_is_required: 'La clé client en direct est requise',
  live_end_point_prefix_is_required:
    'Le préfixe de point de terminaison en direct est requis',
  test_api_key: 'Tester la clé API',
  test_merchant_account_name: 'Tester le nom du compte marchand',
  test_client_key: 'Tester la clé client',
  live_api_key: 'Clé API en direct',
  live_merchant_account_name: 'Nom du compte marchand en direct',
  live_client_key: 'Clé client en direct',
  live_endpoint_prefix: 'Préfixe de point de terminaison en direct',
  issue_payment_failed: 'Il y avait un problème ! Paiement échoué.',
  issue_coupon_creation_failed:
    'Il y avait un problème ! Échec de la création du coupon',
  payment_successful_redirect_to_dashboard:
    'Paiement réussi. Redirection vers la facturation des comptes...',
  coupon_successful_created: 'Coupon créé avec succès',
  payment: 'Paiement',
  billing_details: 'Détails de la facturation',
  full_name: 'Nom complet',
  phone: 'Téléphoner',
  payment_info: 'Information de paiement',
  plan: 'Plan',
  month: 'Mois',
  saving_per_month: 'Économisez 24 $ par mois',
  order_summary: 'Récapitulatif de la commande',
  sub_total: 'Sous-total',
  total: 'Totale',
  complete_payment: 'Paiement complet',
  plan_subscribed: 'Forfait souscrit',
  you_have_been_subscribed_to: 'Vous avez été abonné à',
  your_subscription_will_end_on: 'votre abonnement prendra fin le',
  your_next_payment_date_is: "Votre prochaine date d'échéance de paiement est",
  cancel_subscription: "Annuler l'abonnement",
  something_went_wrong_contact_admin:
    "Une erreur s'est produite.Veuillez contacter l'administrateur",
  delete: 'Supprimer',
  change_password: 'Changer le mot de passe',
  your_new_password_different_from_prev_password:
    'Votre nouveau mot de passe doit être différent des mots de passe utilisés précédemment. Doit contenir au moins 8 caractères.',
  back_to_dashboard: 'Retour au tableau de bord',
  manage_hubspot_form: 'Gérer le formulaire Hubspot',
  sorry_page_not_found: 'Désolé, page introuvable !',
  sorry_could_not_find_page:
    "Désolé, nous n'avons pas trouvé la page que vous recherchez. Peut-être avez-vous mal saisi l'URL ? Assurez-vous de vérifier votre orthographe.",
  go_to_home: 'Aller à la maison',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  phone_number: 'Nom de famille',
  state_region: 'État/Région',
  save_details: 'Enregistrer les détails',
  your_form_is_published: 'Votre formulaire est publié',
  form_preview: 'Aperçu du formulaire',
  embed_code: 'Code intégré',
  add_your_hubspot_portal: 'Voeg je HubSpot Portal(s) toe aan de app',
  select_the_portal_you_want_to_connect:
    'Selecteer de portal die u wilt koppelen',
  setup_payment_gateways: 'Betalingsgateways instellen',
  create_configure_forms: 'Formulier(en) aanmaken, configureren en publiceren',
  comming_soon: 'À venir',
  page_not_found: '404 Page non trouvée',
  billing: 'facturation',
  user_accounts: 'Comptes utilisateur',
  ecommerce: 'commerce électronique',
  product_list: 'Liste de produits',
  reset_password: 'réinitialiser le mot de passe',
  send_mail: 'Envoyer un e-mail',
  connect_with_hubspot:
    'Connectez votre compte HubSpot afin que nous puissions nous connecter à votre portail',
  connect_my_hubspot_account: 'Connecter mon compte Hubspot',
  skip_this_step: 'Passer cette étape',
  connect_with_hubspot_account: 'Connectez votre compte Hubspot',
  fill_the_required_fields: 'Remplissez les champs obligatoires',
  old_password_is_required: "L'ancien mot de passe est requis",
  password_must_be_eight_char: "Mot de passe doit être d'au moins 8 caractères",
  new_password_is_required: 'Un nouveau mot de passe est requis',
  save: 'Sauver',
  old_password: 'ancien mot de passe',
  first_name_is_required: 'Le prénom est requis',
  last_name_is_required: 'Le nom de famille est requis',
  phone_must_contain_10_digit:
    'Le numéro de téléphone doit contenir 10 chiffres',
  address_is_required: "L'adresse est obligatoire",
  zipcode_is_required: 'Le code postal est requis',
  no: 'Non',
  has_been_found: 'a été trouvé.',
  will_expire_today: "expirera aujourd'hui",
  will_expire_on: 'expirera le',
  your_plan: 'Votre plan',
  test_client_id: "Tester l'identifiant du client",
  live_client_id: 'ID client en direct',
  button_layout: 'Disposition des boutons',
  button_shape: 'Forme du bouton',
  button_label: 'Libellé du bouton',
  live_client_id_is_required: "L'identifiant du client en direct est requis.",
  test_client_id_is_required: "L'identifiant du client test est requis.",
  button_layout_is_required: 'La disposition des boutons est requise.',
  button_shape_is_required: 'La forme du bouton est requise.',
  button_label_is_required: 'Le libellé du bouton est requis.',
  test_publishable_key_is_required: 'La clé publiable de test est requise',
  test_publishable_key: 'Tester la clé publiable',
  test_secret_key_is_required: 'La clé secrète de test est requise',
  test_secret_key: 'Tester la clé secrète',
  live_publishable_key_is_required: 'La clé publiable en direct est requise',
  live_publishable_key: 'Clé publiable en direct',
  live_secret_key_is_required: 'La clé secrète en direct est requise',
  live_secret_key: 'Clé secrète en direct',
  feel_free_to_contact_us_anytime:
    "N'hésitez pas à nous contacter à tout moment",
  general: 'général',
  your_trial: 'Votre parcours',
  activate: 'Activer',
  disable: 'Désactiver',
  store_listing: 'Liste de magasin',
  store_url: 'URL du magasin',
  coupon_name: 'Nom du coupon',
  discount_type: 'Type de remise',
  discount_amount: 'montant de la remise',
  discount_value: 'Valeur de remise',
  coupons: 'Coupons',
  coupon_expiry: 'Expiration du coupon',
  stores: 'Magasins',
  create_new_coupon: 'Créer un nouveau coupon',
  create_new_coupon_rule: 'Créer une nouvelle règle de coupon',
  create_new_store: 'Créer un nouveau magasin',
  app_listing: 'Liste des applications',
  create_store: 'Créer un magasin',
  home: 'Maison',
  select: 'Sélectionner',
  loading: 'Chargement',
  coming_soon: 'À venir',
  app: 'app',
  user: 'utilisateur',
  list: 'liste',
  shop: 'magasin',
  blog: 'blog',
  post: 'Publier',
  mail: 'poster',
  chat: 'discuter',
  cards: 'Cartes',
  posts: 'Des postes',
  create: 'Créer',
  kanban: 'kanban',
  banking: 'bancaire',
  booking: 'réservation',
  profile: 'profile',
  account: 'profil',
  product: 'produit',
  invoice: 'facturer',
  details: 'des détails',
  checkout: 'Vérifier',
  calendar: 'calendrier',
  analytics: 'analytique',
  management: 'le management',
  menu_level_1: 'menu niveau 1',
  menu_level_2: 'menu niveau 2',
  menu_level_3: 'menu niveau 3',
  item_disabled: 'élément désactivé',
  item_label: "étiquette de l'article",
  item_caption: "légende de l'article",
  description: 'la description',
  other_cases: 'autres cas',
  item_by_roles: 'élément par rôles',
  only_admin_can_see_this_item: "Seul l'administrateur peut voir cet élément",
  you_have_only: "Vous n'avez que",
  left_in_trial: "jours restants de votre période d'essai",
  update: 'mettre à jour',
  one_currency_gateway:
    'Vous pouvez sélectionner des passerelles de paiement avec un type de devise',
  store_source: 'Origine du magasin',
  coupon_source: 'Origine du coupon',
  prefix: 'préfixe',
  merge_fields: 'Champs de fusion',
  random_characters: 'Caractères aléatoires',
  suffix: 'suffixe',
  flatDiscount: 'Remise plane',
  percentDiscount: 'Remise en pourcentage',
  coupon_usage_limit: "Limite d'utilisation du coupon",
  usage_limit: "limite d'utilisation",
  source_url: 'URL de la source',
  coupon_source_required: 'La source du coupon est requise',
  valid_coupon_snippet_required: 'Code de coupon valide requis',
  select_product: 'Sélectionnez un produit',
  random_characters_required: 'Les caractères aléatoires sont requis',
  valid_suffix_required: 'Le suffixe valide est requis',
  format_required: 'Le format est requis',
  discount_required: 'La remise est requise',
  expires_at: 'Expire le',
  customer_specific: 'Spécifique au client',
  always_active: 'Toujours actif',
  selected_products: 'Produits sélectionnés',
  entire_products: 'Tous les produits',
  enter_value: 'Entrez la valeur',
  enter_percentage: 'Entrez le pourcentage',
  flat_discount: 'Remise plane',
  percent_discount: 'Remise en pourcentage',
  'Create New Coupon': 'Créer un nouveau coupon',
  valid_prefix_required: 'Le préfixe valide est requis',
  merge_field_required: 'Le champ de fusion est requis',
  'Coupon Code Format': 'Format du code de coupon',
  about_discount_coupon: 'À propos du coupon de remise',
  max_value: 'La valeur maximale est de',
  coupon_name_required: 'Le nom du coupon est requis',
  select_store: 'Sélectionnez un magasin',
  store: 'magasin',
  store_required: 'Le magasin est requis',
  discount_coupons: 'Coupons de remise',
  add_coupon_rule: 'Ajouter une règle de coupon',
  all_apps: 'Toutes les applications',
  add_store: 'Ajouter un magasin',
  min_value: 'La valeur minimale est de',
  please_select_store_source: 'Veuillez sélectionner une source de magasin',
  store_source_is_required: 'La source du magasin est requise',
  edit_store: 'Modifier le magasin',
  stores_and_coupons: 'Magasins, règles des coupons, coupons et configuration',
  edit_coupon_rule: 'Modifier la règle du coupon',
  create_coupon_rule: 'Créer une règle de coupon',
  store_url_is_required: "L'URL du magasin est requise",
  applies_to: "S'applique à",
  install: 'Installer',
  manage_app: "GÉRER L'APPLICATION",
  pdf: 'PDF',
  summary: 'Sommaire',
  apps: 'Applications',
  subscribe_now: 'Abonnez-vous maintenant',
  selected_plan: 'Forfait sélectionné',
  year: 'An',
  saving: 'Économie',
  current_plan: 'Plan actuel',
  transaction_history: 'Historique des transactions',
  total_amount: 'Montant total',
  coupon_list: 'Liste des coupons',
  coupon_listing: 'Coupon Listing',
  create_coupon: 'Créer un coupon',
  store_list: 'Liste des magasins',
  you_have_no_store_added:
    "Vous n'avez ajouté aucun magasin. Allez-y et ajoutez d'abord votre magasin.",
  upgrade: 'Mise à niveau',
  price_starting_from: 'Commençant pars',
  price: 'Prix',
  connection: 'lien',
  connected: 'liée',
  not_connected: 'Pas connecté',
  disconnect: 'déconnecter',
  change_plan: 'Changer de forfait',
  active_plan: 'Dates actives',
  merge_field: 'Champ de fusion',
  prefix_merge_field: 'Champ de fusion de préfixe',
  merge_field_prefix: 'Préfixe de champ de fusion',
  sample: 'Goûter',
  search_coupon: 'Coupon de recherche',
  none: 'Aucun',
  email: 'E-mail',
  delete_portal: 'Supprimer le portail',
  warning: 'Supprimer le portail',
  deletePortalWarning:
    'La suppression de Portal annulera tous vos abonnements, liés à toutes vos applications.',
  unable_to_logout: 'Impossible de se déconnecter !',
  authorize: 'Autoriser',
  about_store: 'À propos du magasin',
  automated_coupon_code: 'Coupon automatisé',
  trial_expires_on: "L'essai expire le",
  next_payment_date: 'Prochaine date de paiement',
  limited: 'Limité',
  unlimited: 'Illimité',
  coupon_usage: 'Utilisation du coupon',
  specify_number: 'Vous devez indiquer un nombre',
  coupon_rule: 'Règle des coupons',
  coupon_rule_listing: 'Liste des règles de coupon',
  coupon_rule_list: 'Liste des règles de coupon',
  search_coupon_rule: 'Rechercher une règle de coupon',
  coupon_code: 'Code promo',
  rule_name: 'Nom de la règle',
  active_apps: 'Applications actives',
  inactive_apps: 'Applications inactives',
  coupon_rules: 'Règles des coupons',
  cancel_subscription_warning:
    "Êtes-vous sûr de vouloir annuler l'abonnement ?",
  store_content_line_one:
    "Coupons automatisés par MWB est une application de génération de coupons dynamique polyvalente qui peut facilement être intégrée à votre boutique de commerce électronique en quelques clics. Pour connecter votre boutique de commerce électronique à l'application, suivez les étapes ci-dessous -",
  store_content_line_two:
    'Sélectionnez votre plate-forme de commerce électronique dans le menu déroulant adjacent comme WooCommerce, Magento, etc.',
  store_content_line_three:
    "Ajoutez l'URL de votre boutique de commerce électronique",
  store_content_line_four: 'Cliquez sur Autoriser',
  store_content_line_five:
    "Toutes nos félicitations! L'application Automated Coupons est connectée avec succès à votre boutique de commerce électronique. Vous êtes maintenant sur le point de générer des coupons automatisés dans votre magasin.",
  coupon_content_line_one:
    "Pour créer ou générer des codes de coupon pour votre boutique de commerce électronique avec l'application Automated Coupons, vous devez tenir compte d'un certain ensemble de règles pour faciliter la gestion et la surveillance.",
  coupon_content_line_two: 'Définir un nom unique pour chaque règles de coupon',
  coupon_content_line_three:
    'Sélectionnez le magasin de commerce électronique ciblé pour répertorier et offrir des coupons',
  coupon_content_line_four:
    'Ajoutez des règles de coupon pour définir le format du code de coupon généré. Sous Format de code de coupon, définissez les valeurs des champs tels que le préfixe, le champ de fusion, les caractères générés automatiquement, le suffixe, le type de remise, la valeur du coupon, etc.',
  coupon_content_line_five:
    "Ensuite, ajoutez d'autres règles comme la date d'expiration du coupon, si les coupons sont applicables sur tous les produits ou uniquement sur les produits sélectionnés, la limite d'utilisation du coupon, etc. sous la section S'applique à.",
  coupon_content_line_six:
    "Cliquez sur Enregistrer. Vos codes de coupon seront générés sur la source cible une fois que l'inscription au workflow HubSpot définie déclenchera l'action.",
  coupon_content_line_seven:
    "Remarque : Tous les coupons générés seront répertoriés dans l'application (section Coupons) et également dans votre boutique de commerce électronique connectée.",
  total_coupons: 'Coupons totaux',
  coupons_used: 'Coupons utilisés',
  points_and_rewards: 'Points et récompenses',
  rules: 'Règles',
  create_new_rule: 'Créer une nouvelle règle',
  documentation: 'DOCUMENTATION',
  points_provided: 'Points fournis',
  triggered: 'Déclenché',
  rule_type: 'Type de règle',
  display: 'Affichage',
  Details: 'Détails',
  limit: 'Limite',
  only_give_points: 'Donner uniquement des points',
  times_per: 'fois par',
  product_field: 'Champ de produit',
  product_id: 'Identifiant du produit',
  week: 'La semaine',
  points: 'Points',
  background_color: "Couleur de l'arrière plan",
  widget_settings: 'Paramètres des widgets',
  position: 'Position',
  left: 'La gauche',
  right: 'Droit',
  coupon_settings: 'Paramètres des coupons',
  coupon_prefix: 'Préfixe de coupon',
  hubspot: 'HubSpot',
  hubspot_owner: 'Propriétaire Hubspot',
  store_settings: 'Paramètres du magasin',
  store_currency: 'Devise du magasin',
  settings: 'Réglages',
  select_rule_type: 'Sélectionnez le type de règle',
  title: 'Titre',
  Description: 'La description',
  title_is_required: 'Le titre est obligatoire',
  description_is_required: 'La description est obligatoire',
  rule_type_is_required: 'Le type de règle est requis',
  points_are_required: 'Des points sont requis',
  limit_is_required: 'Une limite est requise',
  do_you_want_to_delete_this_rule: 'Voulez-vous supprimer cette règle ?',
  yes: 'Oui',
  delete_rule: 'Supprimer la règle',
  rewards: 'Récompenses',
  customer: 'Cliente',
  activity: 'activité',
  customer_listing: 'Liste des clients',
  customer_name: 'Nom du client',
  edit_cutsomer: 'Modifier le client',
  search_customer: 'rechercher un client',
  history: "l'histoire",
  notes: 'Remarque',
  create_new_rewards: 'Créer de nouvelles récompenses',
  edit_reward: 'Modifier la récompense',
  update_reward: 'Modifier la récompense',
  Code: 'Code',
  claimed_by: 'Revendiquée par',
  used_at: "Utilisé dans l'ID de commande",
  manage_code: 'Gérer le code',
  reward_title_required: 'Le titre est requis',
  valid_value_required: 'Une valeur valide est requise',
  point_cost: 'Coût en points',
  flat: 'À plat',
  percentage: 'Pourcentage',
  minimum_spend: 'Dépense minimale',
  enable_coupon_expiration: "Activer l'expiration du coupon",
  days: 'Journées',
  cost: 'Coût',
  redeemed: 'Racheté',
  manage_codes: 'Gérer les codes',
  create_new_reward: 'Créer une nouvelle récompense',
  delete_reward: 'Supprimer la récompense',
  do_you_want_to_delete_this_reward: 'Voulez-vous supprimer cette récompense?',
  points_redeem: 'Échange de points',
  reward: 'Récompense',
  delete_customer: 'Supprimer le client',
  do_you_want_to_delete_this_customer: 'Voulez-vous supprimer ce client ?',
  current_points: 'Points actuels',
  activities_completed: 'Activités terminées',
  rewards_calimed: 'Récompenses réclamées',
  total_revenue: 'Revenu total',
  referrals: 'Parrainages',
  give_points: 'Donner des points',
  remove_points: 'Supprimer des points',
  block: 'Bloquer',
  points_approved_immediately_redeemed_for_rewards:
    'Ces points seront approuvés immédiatement et pourront être échangés contre des récompenses.',
  reason: 'Raison',
  reason_is_required: 'La raison est requise',
  remove_approve_points_from_customer:
    'Vous pouvez supprimer les points approuvés de ce client.',
  dont_remove_points_for_order_returns_refunds_etc:
    'Ne retirez pas de points pour les retours de commande, les remboursements, etc.',
  removed_by_pointsandreward_automatically:
    'Ceux-ci seront automatiquement supprimés par les points de récompense de fidélité, les supprimer ici entraînera pour le client la perte du double du nombre de points.',
  points_added_successfully: 'Points ajoutés avec succès.',
  points_removed_successfully: 'Points supprimés avec succès.',
  block_customer: 'Bloquer le client',
  block_your_customer_from_loyalty_program:
    'Cela bloquera le client de votre programme de fidélité.',
  blocked_customer_will: 'Un client bloqué',
  no_loger_earn_new_points: 'ne gagnera plus de nouveaux points',
  and_will: 'et va',
  not_able_to_use_points_they_earned:
    "ne pourra plus utiliser les points qu'il a gagnés",
  customer_blocked_successfully: 'Client bloqué avec succès',
  unblock: 'Débloquer',
  unblock_customer: 'Débloquer le client',
  unblock_your_customer_from_loyalty_program:
    'Cela débloquera le client de votre programme de fidélité.',
  customer_unblocked_successfully: 'Client débloqué avec succès.',
  change_birthday: "Changer l'anniversaire",
  birth_date: 'Date de naissance',
  edit_rule: 'Modifier la règle',
  properties: 'Propriétés',
  workflow: 'Flux de travail',
  show: 'Spectacle',
  Emails: 'E-mails',
  hide: 'Cacher',
  discount_type_is_required: 'Le type de remise est requis',
  thank_you_coupon: "Merci d'avoir créé le coupon!",
  store_details: 'Détails du magasin',
  api_key: 'clé API',
  products: 'des produits',
  sources: 'Sources',
  pending_points: 'Points en attente',
  approve_pending_points_for_customer:
    'Approuver les points en attente pour le client',
  by_clicking_approve_pending_points:
    'En cliquant sur le bouton Approuver, vous pouvez approuver tous les points en attente du client',
  no_pending_points_available: 'Aucun point en attente disponible',
  is_required: 'est requis.',
  widget_position: 'Emplacement des widgets',
  app_title: "Titre de l'application",
  primary_color: 'Couleur primaire',
  text_color: 'Couleur du texte',
  body_background_color: 'Couleur de fond du corps',
  heading_color: 'Couleur du titre',
  body_text_color: 'Couleur du corps du texte',
  accent_color: "Couleur d'accentuation",
  widget_icon: 'Icône de perruque',
  please_provide_valid_widget_icon:
    'Veuillez fournir une icône de widget valide',
  configuration: 'Configuration',
  expire_after: 'Expire après',
  interval: 'Intervalle',
  day: 'Jour',
  coupon_created: 'Coupon créé',
  success_rate: 'Taux de réussite',
  email_needs_to_be_published_before_emails:
    'Les e-mails doivent être publiés avant de créer des workflows.',
  times: 'Fois',
  all: 'tout',
  approved: 'approuvé',
  discount_amount_is_required: 'Le montant de la réduction est requis',
  minimum_spend_value_is_required: 'Une valeur de dépense minimale est requise',
  coupon_usage_limit_is_required:
    "Une limite d'utilisation du coupon est requise",
  custom_name_property: 'Propriété personnalisée HubSpot',
  custom_property_helpText:
    'Propriété personnalisée HubSpot pour le code de coupon',
  created_coupon_rule:
    'Le coupon créé expirera après avoir fourni le numéro. de jours',
  exclude_sale_items: 'Exclure les articles en solde',
  allow_free_shipping: 'Autoriser la livraison gratuite',
  individual_use_only: 'Usage individuel uniquement',
  count_value_required: 'La valeur de comptage est requise',
  listing: 'Référencement',
  exclude_product: 'Exclure les produits',
  config: 'Configuration',
  user_id: 'Identifiant utilisateur',
  hubSpotUser: 'Utilisateur HubSpot',
  something_went_wrong: "Quelque chose s'est mal passé",
  manage_who_can_create_coupons:
    "Gérer qui peut créer des coupons dans HubSpot à l'aide des cartes CRM directement depuis ce panneau",
  please_reauthorize:
    'Veuillez réautoriser votre compte pour accéder à cette fonctionnalité',
  reauthorize_now: 'Réautoriser maintenant',
  apply_category: 'Appliquer la catégorie',
  select_category: 'Sélectionner la catégorie',
  exclude_category: 'Exclure la catégorie',
  included_product_warning:
    'Ce produit est déjà inclus dans la liste des produits exclus.',
  excluded_product_warning:
    'Ce produit est déjà inclus dans la liste des produits sélectionnés.',
  included_category_warning:
    'Cette catégorie est déjà incluse dans la liste des catégories exclues.',
  excluded_category_warning:
    'Cette catégorie est déjà incluse dans la liste des catégories sélectionnées.',
  category_feature_banner:
    'Vous pouvez désormais sélectionner des catégories de produits lors de la création de règles de coupons et de coupons directement à partir de la carte CRM.',
  you_can_not_exclude_category_message:
    'Vous ne pouvez pas exclure cette catégorie car un produit sélectionné en fait partie.',
  you_can_not_select_this_product_message:
    'Vous ne pouvez pas sélectionner ce produit car sa catégorie figure déjà dans la liste des catégories exclues.',
};

export default fr;
