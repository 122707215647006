/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DatePicker, LoadingButton } from '@mui/lab';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  SelectChangeEvent,
  Stack,
  Backdrop,
  CircularProgress,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import {
  CREATE_AUTOMATED_COUPON,
  GET_COUPON_CATEGORY,
  GET_WOO_BRANDS,
  STORE_LISTING_API_URL,
  WOO_PRODUCTS_GET_URL,
} from 'api/ApiConstants/DiscountCouponsApiConstants';
import ComboBox, { CrmComboBoxType } from 'components/ComboBox';
import PartialPageSpinner from 'components/PartialPageSpinner';
import {
  IWooBrands,
  IWooCategory,
  IWooExcludedProducts,
  IWooProducts,
} from 'containers/common/dashboard/EcommerceProductListType';
import usePortalId from 'hooks/portal/usePortalId';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { useLocation } from 'react-router-dom';
import { AUTOMATEDCOUPON } from 'routes/paths';
import { isApiError } from 'utils/apiFunctions';
import axiosInstance from 'utils/axios';
import { validPrice } from 'utils/formatNumber';
import { CouponRuleType, WooStore } from '../../DiscountCouponsType';
import SuccessOnCouponCreation from '../SuccessPopup';

const wooCategoryRequest = async (
  storeId: number,
  search: string,
  page: number,
  appCode: string,
  accessToken: string,
  portalId: number
): Promise<{ data: IWooCategory[]; currentPage: number }> => {
  const { data } = await axiosInstance.get<{
    data: IWooCategory[];
    currentPage: number;
  }>(GET_COUPON_CATEGORY, {
    params: {
      appCode,
      storeId,
      search,
      page: page,
      per_page: 20,
      portalId,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export const useWooCategory = (params: {
  appCode: string;
  storeId: number;
  search: string;
  portalId: number;
  accessToken: string;
}): UseInfiniteQueryResult<{
  data: IWooCategory[];
  plugin_brand?: string;
  currentPage: number;
}> => {
  return useInfiniteQuery(
    [
      GET_COUPON_CATEGORY,
      params.search,
      params.storeId,
      params.appCode,
      params.portalId,
    ],
    ({ pageParam }) =>
      wooCategoryRequest(
        params.storeId,
        params.search,
        pageParam,
        params.appCode,
        params.accessToken,
        params.portalId
      ),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: prevPage =>
        prevPage.data.length > 0 ? prevPage.currentPage + 1 : undefined,
      enabled: params.storeId !== -1,
    }
  );
};

const useWooProducts = (params: {
  appCode: string;
  storeId: number;
  search: string;
  portalId: number;
  accessToken: string;
  brand: string | string[];
}): UseInfiniteQueryResult<{ data: IWooProducts[]; currentPage: number }> => {
  return useInfiniteQuery(
    [
      WOO_PRODUCTS_GET_URL,
      params.search,
      params.storeId,
      params.appCode,
      params.portalId,
      params.brand,
    ],
    ({ pageParam }) =>
      wooProductsRequest(
        params.storeId,
        params.search,
        pageParam,
        params.appCode,
        params.portalId,
        params.accessToken,
        params.brand
      ),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: prevPage =>
        prevPage.data.length > 0 ? prevPage.currentPage + 1 : undefined,
      enabled: params.storeId !== -1,
    }
  );
};

const wooProductsRequest = async (
  storeId: number,
  search: string,
  page: number,
  appCode: string,
  portalId: number,
  accessToken: string,
  brand: string | string[]
): Promise<{ data: IWooProducts[]; currentPage: number }> => {
  const { data } = await axiosInstance.get<{
    data: IWooProducts[];
    currentPage: number;
  }>(WOO_PRODUCTS_GET_URL, {
    params: {
      appCode,
      storeId,
      search,
      page: page,
      per_page: 20,
      portalId,
      brand,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

const wooBrandsRequest = async (
  storeId: number,
  search: string | string[],
  page: number,
  appCode: string,
  accessToken: string,
  portalId: number
): Promise<{ data: IWooBrands[]; currentPage: number }> => {
  const { data } = await axiosInstance.get<{
    data: IWooBrands[];
    currentPage: number;
  }>(GET_WOO_BRANDS, {
    params: {
      appCode,
      storeId,
      search,
      page: page,
      per_page: 10,
      portalId,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export const useWooBrands = (params: {
  appCode: string;
  storeId: number;
  search: string;
  portalId: number;
  accessToken: string;
}): UseInfiniteQueryResult<{
  data: IWooBrands[];
  currentPage: number;
}> => {
  return useInfiniteQuery(
    [
      GET_WOO_BRANDS,
      params.search,
      params.storeId,
      params.appCode,
      params.portalId,
    ],
    ({ pageParam }) =>
      wooBrandsRequest(
        params.storeId,
        params.search,
        pageParam,
        params.appCode,
        params.accessToken,
        params.portalId
      ),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: prevPage =>
        prevPage.data.length > 0 ? prevPage.currentPage + 1 : undefined,
      enabled: params.storeId !== -1,
      keepPreviousData: true,
    }
  );
};

const wooCommerceStoresRequest = async (
  portalId: number,
  appCode: string,
  accessToken: string
): Promise<{
  data: WooStore[];
}> => {
  const data = await axiosInstance.get<{
    data: WooStore[];
  }>(STORE_LISTING_API_URL, {
    params: {
      portalId,
      appCode,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data.data;
};

const useWooCommerceStores = (
  portalId: number,
  appCode: string,
  accessToken: string
): UseQueryResult<{
  data: WooStore[];
}> => {
  return useQuery(
    [STORE_LISTING_API_URL],
    () => wooCommerceStoresRequest(portalId, appCode, accessToken),
    {
      refetchOnWindowFocus: false,
    }
  );
};

interface WooType {
  storeId: number;
  code: string;
  discountType: string;
  discountValue: number;
  // expiresAt: string;
  // relative: number;
  usageLimit: number;
  customNameProperty: string;
  // customer_specific: boolean;
}

const initialErrorStateData = {
  storeId: '',
  code: '',
  discountType: '',
  discountValue: '',
  // relative: '',
  // expiresAt: '',
  usageLimit: '',
  // customer_specific: '',
};

type Props = {
  accessToken: string | null;
  couponDetails?: CouponRuleType;
};

const WoocommerceForm: FunctionComponent<Props> = ({
  accessToken,
  couponDetails,
}) => {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const { data } = useWooCommerceStores(
    Number(portalId),
    AUTOMATEDCOUPON,
    accessToken as string
  );
  const isErrorRef = useRef(false);
  const [searchProductVal, setSearchProductVal] = useState('');
  const search = useLocation().search;
  const { enqueueSnackbar } = useSnackbar();
  const objectIdParam = new URLSearchParams(search).get('objectId');
  const emailParam = new URLSearchParams(search).get('email');
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isStoreSelected, setIsStoreSelected] = useState<boolean>(false);
  const [stateError, setStateError] = useState(initialErrorStateData);
  const [searchBrandVal, setSearchBrandVal] = useState('');
  const [dateType, setDateType] = useState('alwaysActive');
  const [searchExcludeProduct, setSearchExcludeProduct] = useState('');
  const [brandsOptions, setBrandsOptions] = useState<IWooBrands[] | []>([]);
  const [relativeDays, setRelativeDays] = useState<number | string>(1);
  const [selectedBrand, setSelectedBrand] = useState<IWooBrands[] | []>([]);
  const [checkBoxItems, setCheckBoxItems] = useState({
    excludeSaleItems: false,
    freeShipping: false,
    individualCoupon: false,
  });
  const [searchCategoryVal, setSearchCategoryVal] = useState('');
  const [searchExcludeCategory, setSearchExcludeCategory] = useState('');
  const [excludedProducts, setExcludedProducts] = useState<
    IWooExcludedProducts[] | []
  >([]);
  const [activeDate, setActiveDate] = useState<string | null>(
    couponDetails?.expiresAt
      ? new Date(parseInt(couponDetails?.expiresAt)).toISOString().slice(0, 10)
      : new Date(Date.now() + 3600 * 1000 * 24).toISOString().slice(0, 10) ||
          null
  );

  const [categoryOption, setCategoryOption] = useState<IWooCategory[] | []>([]);
  const [excludeCategoryOption, setExcludeCategoryOption] = useState<
    IWooCategory[] | []
  >([]);

  const [selectedCategory, setSelectedCategory] = useState<IWooCategory[] | []>(
    couponDetails?.productCategoryId ? couponDetails.productCategoryId : []
  );

  const [excludedCategory, setExcludedCategory] = useState<IWooCategory[] | []>(
    couponDetails?.excludeProductCategoryId
      ? couponDetails.excludeProductCategoryId
      : []
  );

  const [productValue, setProductValue] = useState<any>({
    includedProduct: [],
    excludedProducts: [],
  });

  const [categoryValue, setCategoryValue] = useState<any>({
    includedCategory: [],
    excludedCategory: [],
  });

  const [couponInfo, setCouponInfo] = useState<WooType>({
    storeId: 0,
    code: '',
    discountType: '',
    discountValue: 0,
    usageLimit: 1,
    customNameProperty: '',
  });
  const defaultCouponInfo = {
    storeId: 0,
    code: '',
    discountType: '',
    discountValue: 0,
    usageLimit: 1,
    customNameProperty: '',
  };
  const [selectedProducts, setSelectedProducts] = useState<IWooProducts[] | []>(
    couponDetails?.productIds ? couponDetails.productIds : []
  );

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    if (e.target.name === 'storeId') {
      setSelectedProducts(
        couponDetails?.productIds ? couponDetails.productIds : []
      );
      if (e.target.value === '0' || e.target.value === '') {
        setIsStoreSelected(false);
      } else {
        setIsStoreSelected(true);
      }
    }
    setCouponInfo({ ...couponInfo, [e.target.name]: e.target.value });
  };

  // const handleChangeStore = (
  //   e:
  //     | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  //     | SelectChangeEvent
  // ) => {
  //   setSelectedStore(e.target.value);
  // };

  // const onChangeGatewayCheck = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   checked: boolean
  // ) => {
  //   const checkedStatus = (event.target as HTMLInputElement).checked;
  //   if (checkedStatus) {
  //     setCouponInfo({ ...couponInfo, [event.target.name]: true });
  //   } else {
  //     setCouponInfo({ ...couponInfo, [event.target.name]: false });
  //   }
  // };

  const {
    data: wooProducts,
    fetchNextPage,
    isFetching,
  } = useWooProducts({
    search: searchProductVal || searchExcludeProduct,
    storeId: couponInfo.storeId,
    appCode: AUTOMATEDCOUPON,
    portalId: Number(portalId),
    brand: '' || selectedBrand ? selectedBrand.map(item => item.name) : [],
    accessToken: accessToken as string,
  });

  const {
    data: wooCategory,
    fetchNextPage: fetchMorePage,
    isFetching: fetchingCategory,
  } = useWooCategory({
    search: searchCategoryVal || searchExcludeCategory,
    storeId: couponInfo.storeId,
    appCode: AUTOMATEDCOUPON,
    portalId: Number(portalId),
    accessToken: accessToken as string,
    // brand: '' || selectedBrand ? selectedBrand.map(item => item.name) : [],
  });

  const {
    data: wooBrands,
    isFetching: fetching,
    isLoading,
  } = useWooBrands({
    search: searchBrandVal,
    storeId: couponInfo.storeId,
    appCode: AUTOMATEDCOUPON,
    portalId: Number(portalId),
    accessToken: accessToken as string,
  });

  const brandOptions = React.useMemo(() => {
    const allProducts: IWooBrands[] = [];

    wooBrands?.pages.forEach(page => {
      allProducts.push(...page.data);
    });

    return allProducts;
  }, [wooBrands?.pages.length]);

  const brands: IWooBrands[] = [];
  useEffect(() => {
    if (wooBrands?.pages.length) {
      wooBrands?.pages.forEach(page => {
        brands.push(...page.data);
      });
    }
    setBrandsOptions(brands);
  }, [wooBrands?.pages]);

  const category: IWooCategory[] = [];
  useEffect(() => {
    if (wooCategory?.pages.length) {
      wooCategory?.pages.forEach(page => {
        category.push(...page.data);
      });
    }
    setCategoryOption(category);
    setExcludeCategoryOption(category);
  }, [wooCategory?.pages]);

  const setBrands = (brands: any) => {
    setSelectedBrand(brands);
  };

  const setExcludeProduct = (products: any) => {
    let newArr: any[] = products;
    if (products.length) {
      products.forEach((item: any) => {
        if (productValue.includedProduct.includes(item.id)) {
          newArr = products.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('excluded_product_warning'), {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
          return;
        }
      });
    }

    setProductValue({
      ...productValue,
      excludedProducts: newArr.map((product: any) => product.id),
    });
    setExcludedProducts(newArr);
  };

  const setProducts = (products: any) => {
    let proExist = false;

    if (excludedCategory.length) {
      excludedCategory.map(item => {
        products.map((prod: any) => {
          prod.categories.map((cate: any) => {
            if (cate.id == item.id) {
              proExist = true;
              enqueueSnackbar(t('you_can_not_select_this_product_message'), {
                variant: 'warning',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              });
              return;
            }
          });
        });
      });
    }

    let newArr: any[] = products;
    if (products.length && !proExist) {
      products.forEach((item: any) => {
        if (productValue.excludedProducts.includes(item.id)) {
          newArr = products.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('included_product_warning'), {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
          return;
        }
      });
    }
    if (!proExist) {
      setProductValue({
        ...productValue,
        includedProduct: newArr.map((product: any) => product.id),
      });
      setSelectedProducts(newArr);
    }
  };

  const setCategory = (category: any) => {
    // console.log(category);
    let newArr: any[] = category;
    if (category.length) {
      category.forEach((item: any) => {
        if (categoryValue.excludedCategory.includes(item.id)) {
          newArr = category.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('included_category_warning'), {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
          return;
        }
      });
    }
    setCategoryValue({
      ...categoryValue,
      includedCategory: newArr.map((product: any) => product.id),
    });
    setSelectedCategory(newArr);
  };

  const setExcludeCategory = (category: any) => {
    let proExist = false;

    if (selectedProducts.length) {
      selectedProducts.map(item => {
        item.categories.map((cate: any) => {
          category.map((item2: any) => {
            if (item2.id == cate.id) {
              enqueueSnackbar(t('you_can_not_exclude_category_message'), {
                variant: 'warning',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              });
              proExist = true;
              return;
            }
          });
        });
      });
    }

    let newArr: any[] = category;
    if (category.length && !proExist) {
      category.forEach((item: any) => {
        if (categoryValue.includedCategory.includes(item.id)) {
          newArr = category.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('excluded_category_warning'), {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
          return;
        }
      });
    }
    if (!proExist) {
      setCategoryValue({
        ...categoryValue,
        excludedCategory: newArr.map((product: any) => product.id),
      });
      setExcludedCategory(newArr);
    }
  };

  const handleProductScrolledToBottom = () => {
    fetchNextPage();
  };

  const handleCategoryScrollToBottom = () => {
    fetchMorePage();
  };

  const handleRelativeDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRelativeDays((event.target as HTMLInputElement).value);
  };

  const handleActiveDateType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateType((event.target as HTMLInputElement).value);
  };

  const options = React.useMemo(() => {
    const allProducts: IWooProducts[] = [];
    wooProducts?.pages.forEach(page => {
      allProducts.push(...page.data);
    });

    return allProducts;
  }, [wooProducts?.pages.length]);

  const validateForm = (validateFormData: WooType) => {
    const validate = {
      storeId: (storeId: string) =>
        validPrice(parseFloat(storeId)) && parseFloat(storeId) > 0
          ? true
          : t('select_store'),
      code: (code: string) =>
        code.trim().length > 0 ? true : t('code_is_required'),
      discountType: (discountType: string) =>
        discountType.trim().length > 0 ? true : t('discount_type_is_required'),
      discountValue: (discountValue: string) =>
        validPrice(parseFloat(discountValue)) && parseFloat(discountValue) > 0
          ? true
          : t('fill_valid_amount'),
      // expiresAt: () => true,
      usageLimit: () => true,
      customNameProperty: () => true,
      // customer_specific: () => true,
    };
    const errObj = {
      ...initialErrorStateData,
    };
    let errorFieldsVar = false;
    _.forIn(validateFormData, (value, key) => {
      const error = validate[key as keyof typeof validate](value as never);
      if (error !== true) {
        errorFieldsVar = true;
      }
      // @ts-ignore
      errObj[key] = error ?? '';
    });
    isErrorRef.current = errorFieldsVar;
    setStateError({ ...stateError, ...errObj });
  };

  const handleSubmitWoo = async (event: any) => {
    validateForm(couponInfo);
    if (isErrorRef.current) {
      return;
    }
    try {
      setIsSubmitting(true);
      await axiosInstance.post(
        CREATE_AUTOMATED_COUPON,
        {
          portalId: Number(portalId),
          storeId: Number(couponInfo.storeId),
          appCode: AUTOMATEDCOUPON,
          objectId: objectIdParam,
          email: emailParam,
          code: couponInfo.code,
          discountType: couponInfo.discountType,
          discountValue: Number(couponInfo.discountValue),
          expiresType:
            dateType === 'expiresAt'
              ? 'date'
              : dateType === 'relative'
              ? 'days'
              : 'no_expire',
          expiresAt:
            dateType === 'expiresAt' && activeDate !== null
              ? activeDate
              : dateType === 'relative'
              ? relativeDays
              : null,
          productIds: selectedProducts.map(product => product.id),
          brands: selectedBrand ? selectedBrand.map(brand => brand.id) : [],
          excludeProductIds: excludedProducts
            ? excludedProducts.map(brand => brand.id)
            : [],
          productCategoryId: categoryValue.includedCategory,
          excludeProductCategoryId: categoryValue.excludedCategory,
          usageLimit: couponInfo.usageLimit,
          customNameProperty: couponInfo.customNameProperty,
          excludeSaleItems: checkBoxItems.excludeSaleItems,
          freeShipping: checkBoxItems.freeShipping,
          individualCoupon: checkBoxItems.individualCoupon,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsSubmitting(false);
      setConfirmDialogueOpen(true);
      enqueueSnackbar(t('coupon_successful_created'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      setCouponInfo(couponInfo => ({
        ...couponInfo,
        ...defaultCouponInfo,
      }));
      setIsStoreSelected(false);
      setCheckBoxItems({
        excludeSaleItems: false,
        freeShipping: false,
        individualCoupon: false,
      });
      setSelectedProducts(
        couponDetails?.productIds ? couponDetails.productIds : []
      );
      setExcludedProducts([]);
      setSelectedCategory([]);
      setExcludedCategory([]);
    } catch (error: any) {
      setIsSubmitting(false);
      if (isApiError(error)) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    }
  };

  const confirmDialogueOpenClose = () => {
    setConfirmDialogueOpen(false);
  };

  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  return (
    <>
      <FormControl sx={{ minWidth: '100%', marginTop: 2 }}>
        <InputLabel id='demo-simple-select-helper-label'>
          {t('stores')}
        </InputLabel>
        <Select
          sx={{ width: '478px' }}
          name='storeId'
          labelId='demo-simple-select-helper-label'
          id='demo-simple-select-helper'
          label={t('stores')}
          onChange={handleChange}
          value={couponInfo.storeId.toString()}
          error={stateError.storeId.length ? true : false}
        >
          <MenuItem value='0'>{t('select_store')}</MenuItem>
          {_.map(data?.data, function (store, key) {
            if (store.isConnected) {
              return (
                <MenuItem
                  disabled={!store.isConnected}
                  key={store._id}
                  value={store._id}
                >
                  {store.storeUrl}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
      {isStoreSelected ? (
        <>
          <TextField
            fullWidth
            sx={{ marginTop: 2 }}
            id='outlined-basic'
            name='code'
            label={t('coupon_code')}
            variant='outlined'
            type='text'
            onChange={handleChange}
            value={couponInfo.code}
            error={stateError.code.length ? true : false}
          />
          <FormControl sx={{ minWidth: '100%', marginTop: 2 }}>
            <InputLabel id='demo-simple-select-helper-label'>
              {t('discount_type')}
            </InputLabel>
            <Select
              fullWidth
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              name='discountType'
              value={couponInfo.discountType}
              label={t('discount_type')}
              onChange={handleChange}
              error={stateError.discountType.length ? true : false}
            >
              <MenuItem value='flat'>{t('flat')}</MenuItem>
              <MenuItem value='percentage'>{t('percentage')}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            sx={{ marginTop: 2, mb: 2 }}
            id='outlined-basic'
            name='discountValue'
            variant='outlined'
            label={t('discount_amount')}
            type='number'
            onChange={handleChange}
            value={couponInfo.discountValue}
            error={stateError.discountValue.length ? true : false}
          />
          <div className='my-5'>
            <RadioGroup
              row
              className='mt-3 mb-5 flex flex-row gap-2'
              aria-labelledby='demo-radio-buttons-group-label'
              value={dateType}
              onChange={handleActiveDateType}
            >
              <FormControlLabel
                value='alwaysActive'
                control={<Radio />}
                label={t('always_active')}
              />
              <FormControlLabel
                value='expiresAt'
                control={<Radio />}
                label={t('expires_at')}
              />
              <FormControlLabel
                value='relative'
                control={<Radio />}
                label={t('relative')}
              />
            </RadioGroup>
          </div>
          {dateType === 'expiresAt' && (
            <Stack sx={{ minWidth: '100%' }}>
              <DatePicker
                disablePast
                inputFormat='yyyy/MM/dd'
                renderInput={props => <TextField {...props} />}
                label={t('expires_at')}
                className='mt-5'
                value={activeDate}
                onChange={setActiveDate}
              />
            </Stack>
          )}

          {dateType === 'relative' && (
            <TextField
              fullWidth
              id='outlined-basic'
              name='relative'
              sx={{ marginTop: 2 }}
              label={t('relative')}
              variant='outlined'
              type='number'
              onChange={handleRelativeDays}
              value={relativeDays}
              helperText={t('created_coupon_rule')}
            />
          )}
          {brandOptions.length ? (
            <Stack sx={{ minWidth: '100%' }}>
              <ComboBox
                loading={fetching}
                label={' '}
                options={brandsOptions}
                values={selectedBrand}
                taglabel={t('select_brand')}
                onChange={brands => setBrands(brands as CrmComboBoxType[])}
                inputSearchValue={searchBrandVal}
                onInputChange={setSearchBrandVal}
                multiple
              />
            </Stack>
          ) : (
            isLoading && (
              <Backdrop
                sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
                open={fetching}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            )
          )}

          <Stack sx={{ minWidth: '100%' }}>
            <ComboBox
              loading={isFetching}
              label={' '}
              options={options}
              taglabel={t('select_product')}
              values={selectedProducts}
              onChange={products => setProducts(products as CrmComboBoxType[])}
              inputSearchValue={searchProductVal}
              onInputChange={setSearchProductVal}
              multiple
              scrolledToBottomCallback={handleProductScrolledToBottom}
            />
          </Stack>
          <Stack sx={{ minWidth: '100%' }}>
            <ComboBox
              loading={isFetching}
              label={' '}
              options={options}
              taglabel={t('exclude_product')}
              values={excludedProducts}
              onChange={products =>
                setExcludeProduct(products as CrmComboBoxType[])
              }
              inputSearchValue={searchExcludeProduct}
              onInputChange={setSearchExcludeProduct}
              multiple
              scrolledToBottomCallback={handleProductScrolledToBottom}
            />
          </Stack>
          <Stack sx={{ minWidth: '100%' }}>
            <ComboBox
              loading={fetchingCategory}
              label={' '}
              options={categoryOption}
              taglabel={t('select_category')}
              values={selectedCategory}
              onChange={category => setCategory(category as CrmComboBoxType[])}
              inputSearchValue={searchCategoryVal}
              onInputChange={setSearchCategoryVal}
              multiple
              scrolledToBottomCallback={handleCategoryScrollToBottom}
            />
          </Stack>
          <Stack sx={{ minWidth: '100%' }}>
            <ComboBox
              loading={fetchingCategory}
              label={' '}
              options={excludeCategoryOption}
              taglabel={t('exclude_category')}
              values={excludedCategory}
              onChange={category =>
                setExcludeCategory(category as CrmComboBoxType[])
              }
              inputSearchValue={searchExcludeCategory}
              onInputChange={setSearchExcludeCategory}
              multiple
              scrolledToBottomCallback={handleCategoryScrollToBottom}
            />
          </Stack>
          <TextField
            fullWidth
            id='outlined-basic'
            name='usageLimit'
            sx={{ marginTop: 2 }}
            label={t('usage_limit')}
            variant='outlined'
            type='number'
            onChange={handleChange}
            value={couponInfo.usageLimit}
          />
          <TextField
            fullWidth
            id='outlined-basic'
            name='customNameProperty'
            sx={{ marginTop: 2 }}
            label='HubSpot Custom Property'
            variant='outlined'
            onChange={handleChange}
            value={couponInfo.customNameProperty}
            helperText='HubSpot custom property for coupon code'
          />
          <Stack sx={{ minWidth: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name='excludeSaleItems'
                  checked={checkBoxItems.excludeSaleItems}
                  onChange={() => {
                    setCheckBoxItems({
                      excludeSaleItems: !checkBoxItems.excludeSaleItems,
                      freeShipping: checkBoxItems.freeShipping,
                      individualCoupon: checkBoxItems.individualCoupon,
                    });
                  }}
                />
              }
              label='Exclude sale items'
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='freeShipping'
                  checked={checkBoxItems.freeShipping}
                  onChange={() => {
                    setCheckBoxItems({
                      excludeSaleItems: checkBoxItems.excludeSaleItems,
                      freeShipping: !checkBoxItems.freeShipping,
                      individualCoupon: checkBoxItems.individualCoupon,
                    });
                  }}
                />
              }
              label='Allow free shipping'
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='individualCoupon'
                  checked={checkBoxItems.individualCoupon}
                  onChange={() => {
                    setCheckBoxItems({
                      excludeSaleItems: checkBoxItems.excludeSaleItems,
                      freeShipping: checkBoxItems.freeShipping,
                      individualCoupon: !checkBoxItems.individualCoupon,
                    });
                  }}
                />
              }
              label='Individual use only'
            />
          </Stack>
        </>
      ) : null}
      <LoadingButton
        fullWidth
        sx={{ marginTop: 2 }}
        size='large'
        disabled={!isStoreSelected}
        type='submit'
        variant='contained'
        aria-label={t('submit')}
        loading={isSubmitting}
        onClick={handleSubmitWoo}
        className='mt-5 py-6'
      >
        {t('create_new_coupon')}
      </LoadingButton>
      <SuccessOnCouponCreation
        open={confirmDialogueOpen}
        handleOpenCloseConfirmDialogue={() => confirmDialogueOpenClose()}
      />
    </>
  );
};

export default WoocommerceForm;
